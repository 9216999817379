<template>
    <a-drawer
        title="Kết quả kiểm tra chuyên môn Stylist"
        :width="720"
        :visible="visible"
        :body-style="{ paddingBottom: '80px' }"
        @close="onClose"
        wrapClassName="form-update-point"
    >
        <a-spin :spinning="isLoading">
            <a-row>
                <a-col class="text-center" :span="7">
                    Ngày phỏng vấn:
                    <span class="text-bold">{{ currentDate || '' }}</span>
                </a-col>
                <a-col class="text-center" :span="10">
                    Họ tên ứng viên:
                    <span class="text-bold">{{
                        candidate.fullName || ''
                    }}</span>
                </a-col>
                <a-col class="text-center" :span="7">
                    Số điện thoại:
                    <span class="text-bold">{{ candidate.phone || '' }}</span>
                </a-col>
            </a-row>

            <div class="content-form">
                <a-form label-align="left" layout="vertical">
                    <div v-for="(points, name) in listPoint" :key="name">
                        <a-divider orientation="left" class="title-field ">
                            {{ name }}
                        </a-divider>
                        <a-row :gutter="40">
                            <a-col
                                :span="12"
                                v-for="point in points"
                                :key="point.id.skillTestId"
                            >
                                <a-form-item
                                    :label="`${point.skillTest.label}:`"
                                    v-bind="{
                                        ...formItemLayout,
                                        ...checkError(point),
                                    }"
                                >
                                    <a-input v-model="point.value">
                                        <div
                                            v-if="point.skillTest.unit"
                                            slot="addonAfter"
                                            style="width: 50px"
                                        >
                                            {{ point.skillTest.unit }}
                                        </div>
                                    </a-input>
                                </a-form-item>
                            </a-col>
                        </a-row>
                    </div>
                </a-form>
            </div>
        </a-spin>
        <div
            :style="{
                position: 'absolute',
                right: 0,
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e9e9e9',
                padding: '10px 16px',
                background: '#fff',
                textAlign: 'left',
                zIndex: 1,
            }"
        >
            <a-button
                :style="{ marginRight: '8px' }"
                type="primary"
                @click="handleSubmit"
            >
                Hoàn tất
            </a-button>
            <a-button @click="onClose">
                Hủy
            </a-button>
        </div>
    </a-drawer>
</template>

<script>
import { mapState } from 'vuex';
import { RepositoryFactory } from '../../repository/RepositoryFactory';
const candidateRepository = RepositoryFactory.get('candidate');
var _ = require('lodash');
import moment from 'moment';

export default {
    props: { candidate: Object, visible: Boolean },

    data() {
        return {
            formItemLayout: {
                labelCol: { span: 12 },
                wrapperCol: { span: 12 },
                labelAlign: 'left',
            },
            isLoading: false,
            currentDate: moment().format('DD/MM/YYYY'),
            listPoint: {},
        };
    },

    computed: {
        ...mapState('round', {
            listRound: 'data',
        }),
        listStatus() {
            const round = this.listRound.find((round) => round.lastStep);
            // const status = round.statuses.filter((sta) => !sta.pending);
            return round.statuses;
        },
        ...mapState({
            user: (state) => state.authenticate.user,
        }),
    },

    watch: {
        visible: {
            handler: function(newVisible, oldVisible) {
                this.$nextTick(() => {
                    if (newVisible == true && oldVisible == false) {
                        this.fetchPointStylist();
                        this.setValueToForm();
                    }
                });
            },
            deep: true,
        },
    },

    methods: {
        async fetchPointStylist() {
            this.isLoading = true;
            try {
                const response = await candidateRepository.fetchPointStylist(
                    this.candidate.id
                );
                const { data: pointStylist } = response.data;
                const pointFormat = _.groupBy(
                    pointStylist,
                    (point) => point.skillTest.group.name
                );
                this.listPoint = pointFormat;
            } catch (error) {
                this.$error({
                    title: 'Lấy thông tin thất bại',
                    okText: 'OK',
                    centered: true,
                    onOk: () => {},
                });
            } finally {
                this.isLoading = false;
            }
        },

        setValueToForm() {
            this.status = this.candidate.status.id;
            this.adminNote = this.candidate.adminNote;
        },

        onClose() {
            this.listPoint = {};
            this.$emit('close');
        },

        checkError(point) {
            if (point.value) {
                if (!point.skillTest.unit) {
                    return {};
                }
                if (!/^\d*$/.test(point.value)) {
                    return {
                        validateStatus: 'error',
                        help: 'Nhập số!',
                    };
                }
                if (
                    point.skillTest.unit &&
                    point.skillTest.unit.split('/')[1]
                ) {
                    const max =
                        point.skillTest.unit &&
                        point.skillTest.unit.split('/')[1].split(' ')[0];

                    if (parseInt(point.value) > max) {
                        return {
                            validateStatus: 'error',
                            help: `Nhỏ hơn ${
                                point.skillTest.unit.split('/')[1]
                            }`,
                        };
                    }
                }
            }
            return {};
        },

        checkExistError() {
            let hasError = false;
            const { listPoint } = this;
            const that = this;
            Object.keys(listPoint).map(function(key) {
                _.forEach(listPoint[key], (point) => {
                    if (!_.isEmpty(that.checkError(point))) {
                        hasError = true;
                        return false;
                    }
                });
            });
            return hasError;
        },

        formatParams() {
            const { id } = this.candidate;
            const { listPoint } = this;
            const { userId, adminId = parseInt(userId) } = this.user;
            let params = {
                id,
                point: [],
            };
            Object.keys(listPoint).map(function(key) {
                _.forEach(listPoint[key], (point) => {
                    // eslint-disable-next-line no-unused-vars
                    const { skillTest, ...pointFormat } = point;
                    params.point.push({
                        ...pointFormat,
                        adminId,
                    });
                });
            });
            return params;
        },

        handleSubmit(e) {
            e.preventDefault();
            if (!this.checkExistError()) {
                const params = this.formatParams();
                this.$emit('on-update', params);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.form-update-point {
    .text-center {
        text-align: center;
    }
    .text-bold {
        font-weight: bold;
    }

    .content-form {
        .title-field {
            margin-top: 20px;
            margin-bottom: 15px;
        }
    }
}
</style>
