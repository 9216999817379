<template>
    <div>
        <a-drawer
            title="Kết quả phỏng vấn tại salon"
            :width="720"
            :visible="visible"
            :body-style="{ paddingBottom: '80px' }"
            @close="onClose"
            wrapClassName="form-update-result"
        >
            <a-row>
                <a-col class="text-center" :span="7">
                    Ngày phỏng vấn:
                    <span class="text-bold">{{ currentDate || '' }}</span>
                </a-col>
                <a-col class="text-center" :span="10">
                    Họ tên ứng viên:
                    <span class="text-bold">{{
                        candidate.fullName || ''
                    }}</span>
                </a-col>
                <a-col class="text-center" :span="7">
                    Số điện thoại:
                    <span class="text-bold">{{ candidate.phone || '' }}</span>
                </a-col>
            </a-row>
            <div class="form-content">
                <a-form
                    :form="form"
                    hide-required-mark
                    label-align="left"
                    layout="vertical"
                >
                    <a-divider orientation="left" class="title">
                        Cập nhật CMND
                    </a-divider>
                    <a-row :gutter="40">
                        <a-col :span="12">
                            <a-form-item
                                label="CMND:"
                                v-bind="{ ...formItemLayout }"
                            >
                                <a-input
                                    v-decorator="[
                                        'idNumber',
                                        {
                                            rules: [
                                                {
                                                    required: true,
                                                    message:
                                                        'Trường này là bắt buộc',
                                                },
                                                {
                                                    pattern: /^[0-9]+$/g,
                                                    message:
                                                        'Số CMND phải là số',
                                                },
                                            ],
                                            validateTrigger: ['blur'],
                                        },
                                    ]"
                                    placeholder="Số CMND"
                                />
                            </a-form-item>
                        </a-col>
                        <a-col :span="12">
                            <a-form-item
                                label="Ngày cấp:"
                                v-bind="{ ...formItemLayout }"
                            >
                                <a-date-picker
                                    style="width: 100%"
                                    v-decorator="[
                                        'idDateCreated',
                                        {
                                            rules: [
                                                {
                                                    required: true,
                                                    message:
                                                        'Trường này là bắt buộc',
                                                },
                                            ],
                                        },
                                    ]"
                                    placeholder="Ngày cấp CMND"
                                    :format="dateFormat"
                                />
                            </a-form-item>
                        </a-col>
                        <a-col :span="12">
                            <a-form-item
                                label="Nơi cấp:"
                                v-bind="{ ...formItemLayout }"
                            >
                                <a-input
                                    v-decorator="[
                                        'idCity',
                                        {
                                            rules: [
                                                {
                                                    required: true,
                                                    message:
                                                        'Trường này là bắt buộc',
                                                },
                                            ],
                                        },
                                    ]"
                                    style="width: 100%"
                                    placeholder="Nơi cấp CMND"
                                />
                            </a-form-item>
                        </a-col>
                    </a-row>
                    <a-row :gutter="40">
                        <a-col :span="12">
                            <a-form-item
                                label=""
                                :wrapper-col="{ span: 16, offset: 8 }"
                            >
                                <div class="square">
                                    <div class="content">
                                        <div class="upload">
                                            <a-upload
                                                v-decorator="['imageIdFront']"
                                                class="f-width f-height "
                                                accept=".jpg,.jpeg,.png"
                                                :action="
                                                    `${
                                                        config.uploadEndpoint
                                                    }/candidates/image/form`
                                                "
                                                name="images"
                                                listType="picture-card"
                                                :fileList="imageIdFront"
                                                @preview="handlePreview"
                                                @change="
                                                    (file) =>
                                                        handleChangeFile(
                                                            file,
                                                            'imageIdFront'
                                                        )
                                                "
                                            >
                                                <div
                                                    v-if="
                                                        imageIdFront.length ===
                                                            0
                                                    "
                                                >
                                                    <a-icon type="plus" />
                                                    <div
                                                        class="ant-upload-text"
                                                    >
                                                        Ảnh mặt trước
                                                    </div>
                                                </div>
                                            </a-upload>
                                            <div
                                                class="mg-top-5"
                                                v-if="imageIdFront.length > 0"
                                            >
                                                Ảnh mặt trước
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </a-form-item>
                        </a-col>
                        <a-col :span="12">
                            <a-form-item
                                label=""
                                :wrapper-col="{ span: 16, offset: 8 }"
                            >
                                <div class="square">
                                    <div class="content">
                                        <div class="upload">
                                            <a-upload
                                                v-decorator="['imageIdBack']"
                                                class="f-width f-height "
                                                accept=".jpg,.jpeg,.png"
                                                :action="
                                                    `${
                                                        config.uploadEndpoint
                                                    }/candidates/image/form`
                                                "
                                                name="images"
                                                listType="picture-card"
                                                :fileList="imageIdBack"
                                                @preview="handlePreview"
                                                @change="
                                                    (file) =>
                                                        handleChangeFile(
                                                            file,
                                                            'imageIdBack'
                                                        )
                                                "
                                            >
                                                <div
                                                    v-if="
                                                        imageIdBack.length === 0
                                                    "
                                                >
                                                    <a-icon type="plus" />
                                                    <div
                                                        class="ant-upload-text"
                                                    >
                                                        Ảnh mặt sau
                                                    </div>
                                                </div>
                                            </a-upload>
                                            <div
                                                class="mg-top-5"
                                                v-if="imageIdFront.length > 0"
                                            >
                                                Ảnh mặt sau
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </a-form-item>
                        </a-col>
                    </a-row>

                    <a-divider orientation="left">
                        Cập nhật ảnh ứng viên
                    </a-divider>

                    <a-row :gutter="40">
                        <a-col :span="12">
                            <a-form-item
                                label=""
                                :wrapper-col="{ span: 16, offset: 8 }"
                            >
                                <div class="square">
                                    <div class="content">
                                        <div class="upload">
                                            <a-upload
                                                v-decorator="['imageFace']"
                                                class="f-width f-height "
                                                accept=".jpg,.jpeg,.png"
                                                :action="
                                                    `${
                                                        config.uploadEndpoint
                                                    }/candidates/image/form`
                                                "
                                                name="images"
                                                listType="picture-card"
                                                :fileList="imageFace"
                                                @preview="handlePreview"
                                                @change="
                                                    (file) =>
                                                        handleChangeFile(
                                                            file,
                                                            'imageFace'
                                                        )
                                                "
                                            >
                                                <div
                                                    v-if="
                                                        imageFace.length === 0
                                                    "
                                                >
                                                    <a-icon type="plus" />
                                                    <div
                                                        class="ant-upload-text"
                                                    >
                                                        Ảnh chân dung
                                                    </div>
                                                </div>
                                            </a-upload>
                                            <div
                                                class="mg-top-5"
                                                v-if="imageIdFront.length > 0"
                                            >
                                                Ảnh chân dung
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </a-form-item>
                        </a-col>
                        <a-col :span="12">
                            <a-form-item
                                label=""
                                :wrapper-col="{ span: 16, offset: 8 }"
                            >
                                <div class="square">
                                    <div class="content">
                                        <div class="upload">
                                            <a-upload
                                                v-decorator="['imageFullBody']"
                                                class="f-width f-height "
                                                accept=".jpg,.jpeg,.png"
                                                :action="
                                                    `${
                                                        config.uploadEndpoint
                                                    }/candidates/image/form`
                                                "
                                                name="images"
                                                listType="picture-card"
                                                :fileList="imageFullBody"
                                                @preview="handlePreview"
                                                @change="
                                                    (file) =>
                                                        handleChangeFile(
                                                            file,
                                                            'imageFullBody'
                                                        )
                                                "
                                            >
                                                <div
                                                    v-if="
                                                        imageFullBody.length ===
                                                            0
                                                    "
                                                >
                                                    <a-icon type="plus" />
                                                    <div
                                                        class="ant-upload-text"
                                                    >
                                                        Ảnh toàn thân
                                                    </div>
                                                </div>
                                            </a-upload>
                                            <div
                                                class="mg-top-5"
                                                v-if="imageIdFront.length > 0"
                                            >
                                                Ảnh toàn thân
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </a-form-item>
                        </a-col>
                    </a-row>

                    <a-divider orientation="left">
                        Kết quả phỏng vấn
                    </a-divider>

                    <a-row :gutter="40">
                        <a-col :span="12">
                            <a-form-item
                                label="Kết quả:"
                                v-bind="{ ...formItemLayout }"
                            >
                                <a-select v-decorator="['status']">
                                    <a-select-option
                                        :key="status.id"
                                        v-for="status in listStatus"
                                        :value="status.id"
                                    >
                                        {{ status.name }}
                                    </a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-col>
                        <a-col :span="12">
                            <a-form-item
                                label="Người đánh giá:"
                                v-bind="{ ...formItemLayout }"
                            >
                                <span class="text-bold">{{
                                    user.fullName
                                }}</span>
                            </a-form-item>
                        </a-col>
                        <a-col :span="24">
                            <a-form-item label="">
                                <a-textarea
                                    v-decorator="['adminNote']"
                                    placeholder="Ghi chú"
                                    :rows="4"
                                />
                            </a-form-item>
                        </a-col>
                    </a-row>
                </a-form>
            </div>
            <div
                :style="{
                    position: 'absolute',
                    right: 0,
                    bottom: 0,
                    width: '100%',
                    borderTop: '1px solid #e9e9e9',
                    padding: '10px 16px',
                    background: '#fff',
                    textAlign: 'left',
                    zIndex: 1,
                }"
            >
                <a-button
                    :style="{ marginRight: '8px' }"
                    type="primary"
                    @click="handleSubmit"
                >
                    Hoàn tất
                </a-button>
                <a-button @click="onClose">
                    Hủy
                </a-button>
            </div>
            <a-modal
                :centered="true"
                :width="500"
                :visible="previewVisible"
                :footer="null"
                @cancel="handleCancel"
            >
                <img alt="example" style="width: 100%" :src="previewImage" />
            </a-modal>
        </a-drawer>
    </div>
</template>

<script>
import moment from 'moment';
import { mapState } from 'vuex';
import config from '../../configs/ApplicationConfig';

const dateFormat = 'DD/MM/YYYY';

export default {
    props: {
        candidate: Object,
        visible: Boolean,
    },

    data() {
        return {
            formItemLayout: {
                labelCol: { span: 8 },
                wrapperCol: { span: 16 },
                labelAlign: 'left',
            },
            config,
            dateFormat,
            form: this.$form.createForm(this, { name: 'FormUpdateResult' }),
            imageIdFront: [],
            imageIdBack: [],
            imageFace: [],
            imageFullBody: [],
            currentDate: moment().format('DD/MM/YYYY'),
            previewVisible: false,
            previewImage: '',
        };
    },

    watch: {
        visible: {
            handler: function(newVisible, oldVisible) {
                this.$nextTick(() => {
                    if (newVisible == true && oldVisible == false) {
                        this.setValueToForm();
                    }
                });
            },
            deep: true,
        },
    },

    computed: {
        ...mapState('round', {
            listRound: 'data',
        }),
        ...mapState({
            user: (state) => state.authenticate.user,
        }),
        listStatus() {
            const round = this.listRound.find((round) => round.lastStep);
            // const status = round.statuses.filter((sta) => !sta.pending);
            return round.statuses;
        },
    },

    methods: {
        onClose() {
            this.form.resetFields();
            this.$emit('close');
        },

        handleChangeFile({ fileList }, field) {
            this[field] = fileList;
        },

        handlePreview(file) {
            this.previewImage = file.url || file.thumbUrl;
            this.previewVisible = true;
        },

        handleCancel() {
            this.previewVisible = false;
        },

        getImage(image) {
            if (typeof image == 'string') {
                return image;
            }
            if (image && image.fileList[0])
                return image.fileList[0].response[0];
            return '';
        },

        handleSubmit(e) {
            e.preventDefault();
            this.form.validateFields(async (err, values) => {
                if (!err) {
                    const imageIdFront = this.getImage(values.imageIdFront);
                    const imageIdBack = this.getImage(values.imageIdBack);
                    const imageFace = this.getImage(values.imageFace);
                    const imageFullBody = this.getImage(values.imageFullBody);

                    let params = {
                        ...values,
                        status: { id: values.status },
                        id: this.candidate.id,
                        imageIdFront,
                        imageIdBack,
                        imageFace,
                        imageFullBody,
                    };
                    if (this.candidate.status.id == values.status) {
                        delete params.status;
                    }
                    this.$emit('on-update', params);
                }
            });
        },

        // reader(MY_URL) {
        //     return new Promise(function(resolve, reject) {
        //         var request = new XMLHttpRequest();
        //         request.open('GET', MY_URL, true);
        //         request.responseType = 'blob';
        //         request.onload = function() {
        //             var reader = new FileReader();
        //             reader.readAsDataURL(request.response);
        //             reader.onload = function(e) {
        //                 resolve(e.target.result);
        //             };
        //         };
        //         request.send();
        //     });
        // },

        formatImage(image) {
            if (image) {
                return [
                    {
                        uid: '-1',
                        name: 'image.png',
                        status: 'done',
                        url: image,
                    },
                ];
            }
            return [];
        },

        async setValueToForm() {
            const {
                idNumber,
                idDateCreated,
                idCity,
                imageIdFront,
                imageIdBack,
                imageFace,
                imageFullBody,
                adminNote,
                status,
            } = this.candidate;
            const { setFieldsValue } = this.form;
            this.imageIdFront = this.formatImage(imageIdFront);
            this.imageIdBack = this.formatImage(imageIdBack);
            this.imageFace = this.formatImage(imageFace);
            this.imageFullBody = this.formatImage(imageFullBody);

            setFieldsValue({
                idNumber,
                idDateCreated: idDateCreated
                    ? moment(new Date(idDateCreated), dateFormat)
                    : undefined,
                idCity,
                adminNote,
                imageIdFront,
                imageIdBack,
                imageFace,
                imageFullBody,
                status: status.id,
            });
        },
    },
};
</script>

<style lang="scss">
.form-update-result {
    .text-center {
        text-align: center;
    }
    .text-bold {
        font-weight: bold;
    }

    .form-content {
        margin-top: 25px;

        .ant-form-item-with-help {
            margin-bottom: 0px;
        }

        .mg-top-5 {
            margin-top: 5px;
        }

        .upload {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            height: 100%;

            .ant-upload.ant-upload-select-picture-card {
                width: 100%;
                height: 100%;
                position: absolute;
            }

            .ant-upload-list-picture-card {
                width: 100%;
                height: 100%;
            }
            .ant-upload-list-picture-card .ant-upload-list-item {
                width: 100%;
                height: 100%;
            }
        }
    }

    .ant-form-item-label {
        text-align: left;
    }

    .avatar-uploader {
        width: 100%;
    }

    .title {
        margin-bottom: 30px;
    }

    .f-width {
        width: 100%;
    }

    .f-height {
        height: 100%;
    }

    .square {
        position: relative;
        width: 100%;
    }

    .square:after {
        content: '';
        display: block;
        padding-bottom: 50%;
    }

    .content {
        position: absolute;
        width: 100%;
        height: 100%;
    }
}
</style>
