var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-drawer',{attrs:{"title":"Kết quả phỏng vấn tại salon","width":720,"visible":_vm.visible,"body-style":{ paddingBottom: '80px' },"wrapClassName":"form-update-result"},on:{"close":_vm.onClose}},[_c('a-row',[_c('a-col',{staticClass:"text-center",attrs:{"span":7}},[_vm._v("\n                Ngày phỏng vấn:\n                "),_c('span',{staticClass:"text-bold"},[_vm._v(_vm._s(_vm.currentDate || ''))])]),_c('a-col',{staticClass:"text-center",attrs:{"span":10}},[_vm._v("\n                Họ tên ứng viên:\n                "),_c('span',{staticClass:"text-bold"},[_vm._v(_vm._s(_vm.candidate.fullName || ''))])]),_c('a-col',{staticClass:"text-center",attrs:{"span":7}},[_vm._v("\n                Số điện thoại:\n                "),_c('span',{staticClass:"text-bold"},[_vm._v(_vm._s(_vm.candidate.phone || ''))])])],1),_c('div',{staticClass:"form-content"},[_c('a-form',{attrs:{"form":_vm.form,"hide-required-mark":"","label-align":"left","layout":"vertical"}},[_c('a-divider',{staticClass:"title",attrs:{"orientation":"left"}},[_vm._v("\n                    Cập nhật CMND\n                ")]),_c('a-row',{attrs:{"gutter":40}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',_vm._b({attrs:{"label":"CMND:"}},'a-form-item',Object.assign({}, _vm.formItemLayout),false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                    'idNumber',
                                    {
                                        rules: [
                                            {
                                                required: true,
                                                message:
                                                    'Trường này là bắt buộc',
                                            },
                                            {
                                                pattern: /^[0-9]+$/g,
                                                message:
                                                    'Số CMND phải là số',
                                            } ],
                                        validateTrigger: ['blur'],
                                    } ]),expression:"[\n                                    'idNumber',\n                                    {\n                                        rules: [\n                                            {\n                                                required: true,\n                                                message:\n                                                    'Trường này là bắt buộc',\n                                            },\n                                            {\n                                                pattern: /^[0-9]+$/g,\n                                                message:\n                                                    'Số CMND phải là số',\n                                            },\n                                        ],\n                                        validateTrigger: ['blur'],\n                                    },\n                                ]"}],attrs:{"placeholder":"Số CMND"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',_vm._b({attrs:{"label":"Ngày cấp:"}},'a-form-item',Object.assign({}, _vm.formItemLayout),false),[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                    'idDateCreated',
                                    {
                                        rules: [
                                            {
                                                required: true,
                                                message:
                                                    'Trường này là bắt buộc',
                                            } ],
                                    } ]),expression:"[\n                                    'idDateCreated',\n                                    {\n                                        rules: [\n                                            {\n                                                required: true,\n                                                message:\n                                                    'Trường này là bắt buộc',\n                                            },\n                                        ],\n                                    },\n                                ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"Ngày cấp CMND","format":_vm.dateFormat}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',_vm._b({attrs:{"label":"Nơi cấp:"}},'a-form-item',Object.assign({}, _vm.formItemLayout),false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                    'idCity',
                                    {
                                        rules: [
                                            {
                                                required: true,
                                                message:
                                                    'Trường này là bắt buộc',
                                            } ],
                                    } ]),expression:"[\n                                    'idCity',\n                                    {\n                                        rules: [\n                                            {\n                                                required: true,\n                                                message:\n                                                    'Trường này là bắt buộc',\n                                            },\n                                        ],\n                                    },\n                                ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"Nơi cấp CMND"}})],1)],1)],1),_c('a-row',{attrs:{"gutter":40}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"","wrapper-col":{ span: 16, offset: 8 }}},[_c('div',{staticClass:"square"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"upload"},[_c('a-upload',{directives:[{name:"decorator",rawName:"v-decorator",value:(['imageIdFront']),expression:"['imageIdFront']"}],staticClass:"f-width f-height ",attrs:{"accept":".jpg,.jpeg,.png","action":((_vm.config.uploadEndpoint) + "/candidates/image/form"),"name":"images","listType":"picture-card","fileList":_vm.imageIdFront},on:{"preview":_vm.handlePreview,"change":function (file) { return _vm.handleChangeFile(
                                                        file,
                                                        'imageIdFront'
                                                    ); }}},[(
                                                    _vm.imageIdFront.length ===
                                                        0
                                                )?_c('div',[_c('a-icon',{attrs:{"type":"plus"}}),_c('div',{staticClass:"ant-upload-text"},[_vm._v("\n                                                    Ảnh mặt trước\n                                                ")])],1):_vm._e()]),(_vm.imageIdFront.length > 0)?_c('div',{staticClass:"mg-top-5"},[_vm._v("\n                                            Ảnh mặt trước\n                                        ")]):_vm._e()],1)])])])],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"","wrapper-col":{ span: 16, offset: 8 }}},[_c('div',{staticClass:"square"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"upload"},[_c('a-upload',{directives:[{name:"decorator",rawName:"v-decorator",value:(['imageIdBack']),expression:"['imageIdBack']"}],staticClass:"f-width f-height ",attrs:{"accept":".jpg,.jpeg,.png","action":((_vm.config.uploadEndpoint) + "/candidates/image/form"),"name":"images","listType":"picture-card","fileList":_vm.imageIdBack},on:{"preview":_vm.handlePreview,"change":function (file) { return _vm.handleChangeFile(
                                                        file,
                                                        'imageIdBack'
                                                    ); }}},[(
                                                    _vm.imageIdBack.length === 0
                                                )?_c('div',[_c('a-icon',{attrs:{"type":"plus"}}),_c('div',{staticClass:"ant-upload-text"},[_vm._v("\n                                                    Ảnh mặt sau\n                                                ")])],1):_vm._e()]),(_vm.imageIdFront.length > 0)?_c('div',{staticClass:"mg-top-5"},[_vm._v("\n                                            Ảnh mặt sau\n                                        ")]):_vm._e()],1)])])])],1)],1),_c('a-divider',{attrs:{"orientation":"left"}},[_vm._v("\n                    Cập nhật ảnh ứng viên\n                ")]),_c('a-row',{attrs:{"gutter":40}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"","wrapper-col":{ span: 16, offset: 8 }}},[_c('div',{staticClass:"square"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"upload"},[_c('a-upload',{directives:[{name:"decorator",rawName:"v-decorator",value:(['imageFace']),expression:"['imageFace']"}],staticClass:"f-width f-height ",attrs:{"accept":".jpg,.jpeg,.png","action":((_vm.config.uploadEndpoint) + "/candidates/image/form"),"name":"images","listType":"picture-card","fileList":_vm.imageFace},on:{"preview":_vm.handlePreview,"change":function (file) { return _vm.handleChangeFile(
                                                        file,
                                                        'imageFace'
                                                    ); }}},[(
                                                    _vm.imageFace.length === 0
                                                )?_c('div',[_c('a-icon',{attrs:{"type":"plus"}}),_c('div',{staticClass:"ant-upload-text"},[_vm._v("\n                                                    Ảnh chân dung\n                                                ")])],1):_vm._e()]),(_vm.imageIdFront.length > 0)?_c('div',{staticClass:"mg-top-5"},[_vm._v("\n                                            Ảnh chân dung\n                                        ")]):_vm._e()],1)])])])],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"","wrapper-col":{ span: 16, offset: 8 }}},[_c('div',{staticClass:"square"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"upload"},[_c('a-upload',{directives:[{name:"decorator",rawName:"v-decorator",value:(['imageFullBody']),expression:"['imageFullBody']"}],staticClass:"f-width f-height ",attrs:{"accept":".jpg,.jpeg,.png","action":((_vm.config.uploadEndpoint) + "/candidates/image/form"),"name":"images","listType":"picture-card","fileList":_vm.imageFullBody},on:{"preview":_vm.handlePreview,"change":function (file) { return _vm.handleChangeFile(
                                                        file,
                                                        'imageFullBody'
                                                    ); }}},[(
                                                    _vm.imageFullBody.length ===
                                                        0
                                                )?_c('div',[_c('a-icon',{attrs:{"type":"plus"}}),_c('div',{staticClass:"ant-upload-text"},[_vm._v("\n                                                    Ảnh toàn thân\n                                                ")])],1):_vm._e()]),(_vm.imageIdFront.length > 0)?_c('div',{staticClass:"mg-top-5"},[_vm._v("\n                                            Ảnh toàn thân\n                                        ")]):_vm._e()],1)])])])],1)],1),_c('a-divider',{attrs:{"orientation":"left"}},[_vm._v("\n                    Kết quả phỏng vấn\n                ")]),_c('a-row',{attrs:{"gutter":40}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',_vm._b({attrs:{"label":"Kết quả:"}},'a-form-item',Object.assign({}, _vm.formItemLayout),false),[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['status']),expression:"['status']"}]},_vm._l((_vm.listStatus),function(status){return _c('a-select-option',{key:status.id,attrs:{"value":status.id}},[_vm._v("\n                                    "+_vm._s(status.name)+"\n                                ")])}),1)],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',_vm._b({attrs:{"label":"Người đánh giá:"}},'a-form-item',Object.assign({}, _vm.formItemLayout),false),[_c('span',{staticClass:"text-bold"},[_vm._v(_vm._s(_vm.user.fullName))])])],1),_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":""}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['adminNote']),expression:"['adminNote']"}],attrs:{"placeholder":"Ghi chú","rows":4}})],1)],1)],1)],1)],1),_c('div',{style:({
                position: 'absolute',
                right: 0,
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e9e9e9',
                padding: '10px 16px',
                background: '#fff',
                textAlign: 'left',
                zIndex: 1,
            })},[_c('a-button',{style:({ marginRight: '8px' }),attrs:{"type":"primary"},on:{"click":_vm.handleSubmit}},[_vm._v("\n                Hoàn tất\n            ")]),_c('a-button',{on:{"click":_vm.onClose}},[_vm._v("\n                Hủy\n            ")])],1),_c('a-modal',{attrs:{"centered":true,"width":500,"visible":_vm.previewVisible,"footer":null},on:{"cancel":_vm.handleCancel}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"alt":"example","src":_vm.previewImage}})])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }