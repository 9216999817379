<template>
    <a-layout-content class="CandidateList">
        <a-spin :spinning="isLoading">
            <a-breadcrumb style="margin: 16px 24px">
                <a-breadcrumb-item>Trang chủ</a-breadcrumb-item>
                <a-breadcrumb-item
                    >Ứng viên phỏng vấn tại salon</a-breadcrumb-item
                >
            </a-breadcrumb>
            <div class="content">
                <div class="content-form">
                    <a-form :form="form">
                        <a-row :gutter="24">
                            <a-col :span="4" :lg="4" :md="6" :xxl="3">
                                <a-form-item label="Vị trí" class="form-item">
                                    <a-select
                                        option-filter-prop="children"
                                        :filter-option="filterOption"
                                        v-decorator="[
                                            'position',
                                            { initialValue: 'ALL' },
                                        ]"
                                        style="width: 100%"
                                    >
                                        <a-select-option value="ALL">
                                            Tất cả
                                        </a-select-option>
                                        <a-select-option
                                            v-for="position in listPosition"
                                            :key="position.id"
                                            :value="position.id"
                                        >
                                            {{ position.name }}
                                        </a-select-option>
                                    </a-select>
                                </a-form-item>
                            </a-col>

                            <a-col :span="4" :lg="4" :md="6" :xxl="3">
                                <a-form-item label="Tên" class="form-item">
                                    <a-input
                                        allow-clear
                                        v-decorator="[
                                            'fullName',
                                            { initialValue: '' },
                                        ]"
                                        style="width: 100%"
                                    />
                                </a-form-item>
                            </a-col>

                            <a-col :span="4" :lg="4" :md="6" :xxl="3">
                                <a-form-item
                                    label="Số điện thoại"
                                    class="form-item"
                                >
                                    <a-input
                                        allow-clear
                                        v-decorator="[
                                            'phone',
                                            { initialValue: '' },
                                        ]"
                                        style="width: 100%"
                                    />
                                </a-form-item>
                            </a-col>

                            <a-col :span="4" :lg="4" :md="6" :xxl="3">
                                <a-form-item label="CMND" class="form-item">
                                    <a-input
                                        allow-clear
                                        v-decorator="[
                                            'idNumber',
                                            { initialValue: '' },
                                        ]"
                                        style="width: 100%"
                                    />
                                </a-form-item>
                            </a-col>

                            <a-col :span="4" class="form-filter_button">
                                <a-form-item>
                                    <a-button
                                        type="primary"
                                        @click="handleSubmit"
                                        icon="search"
                                    >
                                        Xem kết quả
                                    </a-button>
                                </a-form-item>
                            </a-col>

                            <a-col :span="4" class="form-filter_button">
                                <a-form-item>
                                    <a-button @click="handleReset"
                                        >Xóa tìm kiếm</a-button
                                    >
                                </a-form-item>
                            </a-col>
                        </a-row>
                    </a-form>
                </div>

                <div class="content-table-cadidate">
                    <div class="table-cadidate">
                        <a-table
                            size="middle"
                            rowKey="id"
                            :columns="columns"
                            :dataSource="listCandidateFilter"
                            bordered
                            :pagination="{
                                current: page,
                                total: candidate.total,
                                pageSize,
                                pageSizeOptions: ['10', '50', '100'],
                                showSizeChanger: true,
                                size: 'large',
                            }"
                            @change="handleChangePage"
                        >
                            <span slot="index" slot-scope="text, record, index">
                                {{ index + 1 }}
                            </span>
                            <span slot="gender" slot-scope="text">{{
                                text == 'MALE'
                                    ? 'Nam'
                                    : text == 'FEMALE'
                                    ? 'Nữ'
                                    : 'Khác'
                            }}</span>
                            <!-- <span slot="toSalon" slot-scope="record">
                                <a-select
                                    v-decorator="['toSalon']"
                                    style="width: 100%"
                                    show-search
                                    :value="
                                        getSalonAssignee(record.salonAssignee)
                                    "
                                    :filter-option="filterOption"
                                    @change="
                                        (value) =>
                                            handleChangeStatus(record, value)
                                    "
                                >
                                    <a-select-option
                                        v-for="salon in listSalon"
                                        :key="salon.id"
                                        :value="JSON.stringify(salon)"
                                    >
                                        {{ salon.name }}
                                    </a-select-option>
                                </a-select>
                            </span> -->
                            <!-- <span
                                slot="idDateCreated"
                                slot-scope="text, record"
                            >
                                {{ formatDate(record.idDateCreated) }}
                            </span> -->
                            <span slot="birthDay" slot-scope="text, record">
                                {{ formatDate(record.birthDay) }}
                            </span>
                            <span slot="salon" slot-scope="text, record">
                                {{ getSalonName(record.salonAssignee) }}
                            </span>
                            <span slot="history" slot-scope="value, record">
                                <a
                                    class="ant-dropdown-link"
                                    @click="handleViewHistory(record.id)"
                                >
                                    Chi tiết
                                </a>
                            </span>
                            <span slot="point" slot-scope="record">
                                <a
                                    v-if="record.staffType.id == 1"
                                    class="ant-dropdown-link"
                                    @click="handleClickUpdatePoint(record)"
                                >
                                    Cập nhật<a-icon type="edit" />
                                </a>
                            </span>

                            <span slot="action" slot-scope="record">
                                {{ record.status.name }}
                                <a
                                    class="ant-dropdown-link"
                                    @click="handleClickUpdateResult(record)"
                                    style="margin-left: 8px"
                                >
                                    <a-icon type="edit" />
                                </a>
                            </span>
                        </a-table>
                    </div>
                </div>
            </div>
            <FormUpdateResult
                :visible="showFormResult"
                :candidate="candidateSelected"
                @close="handleCloseFormResult"
                @on-update="handleUpdateResult"
            />
            <FormUpdatePointStylist
                :visible="showFormPoint"
                @close="handleCloseFormPoint"
                :candidate="candidateSelected"
                @on-update="handleUpdatePoint"
            />
        </a-spin>
    </a-layout-content>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex';
import { HISTORY_SET_ID_CANDIDATE } from '../../store/mutation-types';
import { formatDate } from '../../utils/Date';
import { RepositoryFactory } from '../../repository/RepositoryFactory';
import FormUpdateResult from './FormUpdateResult';
import FormUpdatePointStylist from './FormUpdatePointStylist';
const candidateRepository = RepositoryFactory.get('candidate');

const columns = [
    {
        title: 'STT',
        key: 'index',
        align: 'center',
        scopedSlots: { customRender: 'index' },
    },
    {
        title: 'Vị trí',
        dataIndex: 'staffType.name',
        align: 'center',
    },
    {
        title: 'Họ tên',
        dataIndex: 'fullName',
        align: 'center',
    },
    {
        title: 'Giới tính',
        dataIndex: 'gender',
        align: 'center',
        scopedSlots: { customRender: 'gender' },
    },
    {
        title: 'CMND',
        dataIndex: 'idNumber',
        align: 'center',
    },
    // {
    //     title: 'Ngày cấp',
    //     dataIndex: 'idDateCreated',
    //     align: 'center',
    //     scopedSlots: { customRender: 'idDateCreated' },
    // },
    // {
    //     title: 'Nơi cấp',
    //     dataIndex: 'idCity',
    //     align: 'center',
    // },
    {
        title: 'Ngày sinh',
        dataIndex: 'birthDay',
        align: 'center',
        scopedSlots: { customRender: 'birthDay' },
    },
    {
        title: 'SĐT',
        dataIndex: 'phone',
        align: 'center',
    },
    {
        title: 'Địa chỉ',
        dataIndex: 'address',
        align: 'center',
    },
    {
        title: 'Khu vực',
        dataIndex: 'city.name',
        align: 'center',
    },
    // {
    //     title: 'Nguồn TD',
    //     dataIndex: 'source.name',
    //     align: 'center',
    // },
    {
        title: 'Salon',
        dataIndex: 'salonAssignee',
        align: 'center',
        scopedSlots: { customRender: 'salon' },
    },
    {
        title: 'Lịch sử PV',
        dataIndex: 'adminNote',
        scopedSlots: { customRender: 'history' },
    },
    // {
    //     title: 'Chuyển đến salon',
    //     align: 'center',
    //     scopedSlots: { customRender: 'toSalon' },
    //     width: '250px',
    // },
    {
        title: 'Điểm stylist',
        key: 'point',
        align: 'center',
        scopedSlots: { customRender: 'point' },
    },
    {
        title: 'Kết quả phỏng vấn',
        key: 'action',
        align: 'center',
        scopedSlots: { customRender: 'action' },
    },
];

export default {
    name: 'CandidateInSalon',

    components: { FormUpdateResult, FormUpdatePointStylist },

    data() {
        return {
            form: this.$form.createForm(this, { name: 'FormFilter' }),
            isLoading: false,
            page: 1,
            pageSize: 10,
            columns,
            showFormResult: false,
            showFormPoint: false,
            candidateSelected: {},
            formatDate,
            filterValue: {},
        };
    },

    created() {
        this.fetchData();
    },

    computed: {
        ...mapState({
            candidate: (state) => state.candidate,
            user: (state) => state.authenticate.user,
        }),
        ...mapState('position', {
            listPosition: 'data',
        }),
        ...mapGetters('salon', {
            listSalon: 'salon',
        }),
        ...mapState('round', {
            listRound: 'data',
        }),
        candidates() {
            if (this.candidate.candidates) {
                return this.candidate.candidates;
            }
            return [];
        },
        listOwnerSalon() {
            return this.user.listSalon.map((salon) => salon.id);
        },

        listCandidateFilter() {
            const indexOfLast = this.page * this.pageSize;
            const indexOfFirst = indexOfLast - this.pageSize;
            if (
                Object.keys(this.filterValue).length === 0 &&
                this.filterValue.constructor === Object
            )
                return this.candidates.slice(indexOfFirst, indexOfLast);

            let result = [];
            result = this.candidates.filter((candidate) => {
                let status = true;
                for (var key in this.filterValue) {
                    if (key == 'position') {
                        if (
                            candidate.staffType.id !== this.filterValue.position
                        )
                            status = false;
                    } else if (key == 'fullName') {
                        if (
                            !candidate.fullName ||
                            !candidate.fullName
                                .toLowerCase()
                                .includes(
                                    this.filterValue.fullName.toLowerCase()
                                )
                        )
                            status = false;
                    } else {
                        if (
                            !candidate[key] ||
                            !candidate[key].includes(this.filterValue[key])
                        )
                            status = false;
                    }
                }
                return status;
            });

            return result.slice(indexOfFirst, indexOfLast);
        },
        roundInSalon() {
            return this.listRound.find((item) => item.lastStep) || {};
        },
    },

    methods: {
        ...mapActions('candidate', {
            fetchCandidate: 'fetchInSalon',
            updateCandidate: 'updateCandidate',
        }),
        ...mapMutations('historyInterview', {
            setIDCandidate: HISTORY_SET_ID_CANDIDATE,
        }),

        handleViewHistory(id) {
            this.setIDCandidate(id);
        },

        async fetchData() {
            this.isLoading = true;
            try {
                const params = {
                    salons: this.listOwnerSalon,
                };
                await this.fetchCandidate(params);
            } catch (error) {
                const { data } = error.response;
                // eslint-disable-next-line no-console
                console.log(data.message);
                this.$error({
                    title: 'Lấy thông tin thất bại!',
                    okText: 'OK',
                    centered: true,
                    onOk: () => {},
                });
            }
            this.isLoading = false;
        },

        // handleFilter(values) {
        //     this.page = 1;
        //     this.pageSize = 10;
        //     this.filterValue = values;
        // },

        handleChangePage(pagination) {
            this.page = pagination.current;
            this.pageSize = pagination.pageSize;
            // this.fetchData();
        },

        getValueFilter(values) {
            const newValue = { ...values };
            for (var propName in values) {
                if (
                    newValue[propName] === null ||
                    newValue[propName] === '' ||
                    newValue[propName] === 'ALL'
                ) {
                    delete newValue[propName];
                }
            }

            return newValue;
        },

        handleSubmit() {
            // e.preventDefault();
            this.form.validateFields((err, values) => {
                this.filterValue = this.getValueFilter(values);
                this.page = 1;
                this.pageSize = 10;
                this.fetchData();
            });
        },

        filterOption(input, option) {
            return (
                option.componentOptions.children[0].text
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
            );
        },

        handleReset() {
            this.form.resetFields();
            this.page = 1;
            this.pageSize = 10;
            this.fetchData();
            this.filterValue = {};
        },

        handleCloseFormResult() {
            this.candidateSelected = {};
            this.showFormResult = false;
        },

        handleCloseFormPoint() {
            this.candidateSelected = {};
            this.showFormPoint = false;
        },

        handleClickUpdateResult(record) {
            this.candidateSelected = record;
            this.showFormResult = true;
        },

        handleClickUpdatePoint(record) {
            this.candidateSelected = record;
            this.showFormPoint = true;
        },

        async handleUpdateResult(params) {
            this.isLoading = true;
            this.showFormResult = false;
            const { status, id, adminNote } = params;
            try {
                if (status) {
                    const paramStatus = {
                        adminId: this.user.userId,
                        adminNote,
                        round: this.roundInSalon,
                        status,
                    };
                    await candidateRepository.updateStatusInterview(
                        id,
                        paramStatus
                    );
                }
                await this.updateCandidate(params);
                this.$message.success('Cập nhật kết quả thành công!', 4);
                await this.fetchData();
            } catch (error) {
                this.$error({
                    title: 'Cập nhật kết quả thất bại, hãy thử lại!',
                    okText: 'Tiếp tục',
                    centered: true,
                    onOk: () => {},
                });
            }
            this.candidateSelected = {};
            this.isLoading = false;
        },

        async handleUpdatePoint(params) {
            this.isLoading = true;
            this.showFormPoint = false;
            try {
                await candidateRepository.updatePointStylist(params);
                this.$message.success('Cập nhật điểm thành công!', 4);
            } catch (error) {
                this.$error({
                    title: 'Cập nhật điểm thất bại, hãy thử lại!',
                    okText: 'Tiếp tục',
                    centered: true,
                    onOk: () => {},
                });
            }
            this.candidateSelected = {};
            this.isLoading = false;
        },

        getSalonName(salonAssignee) {
            const salon = this.listSalon.find(
                (salon) => salon.id === salonAssignee
            );
            if (salon) return salon.name;
            return '';
        },
    },
};
</script>

<style lang="scss" scoped>
.content {
    min-height: calc(100vh - 120px);
    background-color: #fff;
    margin: 15px 25px;
    padding: 15px;

    .total-card {
        background: #fbfbfb;
        border-radius: 4px;
        border: 1px solid #d9d9d9;
        padding: 15px;
        .total {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            .title {
                color: rgba(0, 0, 0, 0.85);
                font-size: 14px;
                line-height: 22px;
            }

            .result {
                color: rgba(0, 0, 0, 0.85);
                font-size: 24px;
                line-height: 32px;
            }
        }
        .total-border {
            border-left: 0.5px solid #ccc;
            border-right: 0.5px solid #ccc;
        }
    }
}

.content-form {
    padding: 10px 15px;
    background: #fbfbfb;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    /* margin-top: 20px; */

    .form-item {
        margin-bottom: 10px;
        .ant-form-item-label {
            min-width: 140px;
            text-align: left;
            line-height: unset;
        }

        .ant-form-item-control-wrapper {
            width: 100%;
        }
    }

    .form-filter_button {
        width: auto;
        padding-top: 22px;
    }
}

.content-table-cadidate {
    margin-top: 20px;

    .table-cadidate {
        margin-top: 15px;
    }
}
</style>

<style lang="scss">
.form-item {
    .ant-form-item-label {
        min-width: 140px;
        text-align: left;
        line-height: unset;
    }

    .ant-form-item-control-wrapper {
        width: 100%;
    }
}
</style>
