var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-layout-content',{staticClass:"CandidateList"},[_c('a-spin',{attrs:{"spinning":_vm.isLoading}},[_c('a-breadcrumb',{staticStyle:{"margin":"16px 24px"}},[_c('a-breadcrumb-item',[_vm._v("Trang chủ")]),_c('a-breadcrumb-item',[_vm._v("Ứng viên phỏng vấn tại salon")])],1),_c('div',{staticClass:"content"},[_c('div',{staticClass:"content-form"},[_c('a-form',{attrs:{"form":_vm.form}},[_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":4,"lg":4,"md":6,"xxl":3}},[_c('a-form-item',{staticClass:"form-item",attrs:{"label":"Vị trí"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                        'position',
                                        { initialValue: 'ALL' } ]),expression:"[\n                                        'position',\n                                        { initialValue: 'ALL' },\n                                    ]"}],staticStyle:{"width":"100%"},attrs:{"option-filter-prop":"children","filter-option":_vm.filterOption}},[_c('a-select-option',{attrs:{"value":"ALL"}},[_vm._v("\n                                        Tất cả\n                                    ")]),_vm._l((_vm.listPosition),function(position){return _c('a-select-option',{key:position.id,attrs:{"value":position.id}},[_vm._v("\n                                        "+_vm._s(position.name)+"\n                                    ")])})],2)],1)],1),_c('a-col',{attrs:{"span":4,"lg":4,"md":6,"xxl":3}},[_c('a-form-item',{staticClass:"form-item",attrs:{"label":"Tên"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                        'fullName',
                                        { initialValue: '' } ]),expression:"[\n                                        'fullName',\n                                        { initialValue: '' },\n                                    ]"}],staticStyle:{"width":"100%"},attrs:{"allow-clear":""}})],1)],1),_c('a-col',{attrs:{"span":4,"lg":4,"md":6,"xxl":3}},[_c('a-form-item',{staticClass:"form-item",attrs:{"label":"Số điện thoại"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                        'phone',
                                        { initialValue: '' } ]),expression:"[\n                                        'phone',\n                                        { initialValue: '' },\n                                    ]"}],staticStyle:{"width":"100%"},attrs:{"allow-clear":""}})],1)],1),_c('a-col',{attrs:{"span":4,"lg":4,"md":6,"xxl":3}},[_c('a-form-item',{staticClass:"form-item",attrs:{"label":"CMND"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                        'idNumber',
                                        { initialValue: '' } ]),expression:"[\n                                        'idNumber',\n                                        { initialValue: '' },\n                                    ]"}],staticStyle:{"width":"100%"},attrs:{"allow-clear":""}})],1)],1),_c('a-col',{staticClass:"form-filter_button",attrs:{"span":4}},[_c('a-form-item',[_c('a-button',{attrs:{"type":"primary","icon":"search"},on:{"click":_vm.handleSubmit}},[_vm._v("\n                                    Xem kết quả\n                                ")])],1)],1),_c('a-col',{staticClass:"form-filter_button",attrs:{"span":4}},[_c('a-form-item',[_c('a-button',{on:{"click":_vm.handleReset}},[_vm._v("Xóa tìm kiếm")])],1)],1)],1)],1)],1),_c('div',{staticClass:"content-table-cadidate"},[_c('div',{staticClass:"table-cadidate"},[_c('a-table',{attrs:{"size":"middle","rowKey":"id","columns":_vm.columns,"dataSource":_vm.listCandidateFilter,"bordered":"","pagination":{
                            current: _vm.page,
                            total: _vm.candidate.total,
                            pageSize: _vm.pageSize,
                            pageSizeOptions: ['10', '50', '100'],
                            showSizeChanger: true,
                            size: 'large',
                        }},on:{"change":_vm.handleChangePage},scopedSlots:_vm._u([{key:"index",fn:function(text, record, index){return _c('span',{},[_vm._v("\n                            "+_vm._s(index + 1)+"\n                        ")])}},{key:"gender",fn:function(text){return _c('span',{},[_vm._v(_vm._s(text == 'MALE'
                                ? 'Nam'
                                : text == 'FEMALE'
                                ? 'Nữ'
                                : 'Khác'))])}},{key:"birthDay",fn:function(text, record){return _c('span',{},[_vm._v("\n                            "+_vm._s(_vm.formatDate(record.birthDay))+"\n                        ")])}},{key:"salon",fn:function(text, record){return _c('span',{},[_vm._v("\n                            "+_vm._s(_vm.getSalonName(record.salonAssignee))+"\n                        ")])}},{key:"history",fn:function(value, record){return _c('span',{},[_c('a',{staticClass:"ant-dropdown-link",on:{"click":function($event){return _vm.handleViewHistory(record.id)}}},[_vm._v("\n                                Chi tiết\n                            ")])])}},{key:"point",fn:function(record){return _c('span',{},[(record.staffType.id == 1)?_c('a',{staticClass:"ant-dropdown-link",on:{"click":function($event){return _vm.handleClickUpdatePoint(record)}}},[_vm._v("\n                                Cập nhật"),_c('a-icon',{attrs:{"type":"edit"}})],1):_vm._e()])}},{key:"action",fn:function(record){return _c('span',{},[_vm._v("\n                            "+_vm._s(record.status.name)+"\n                            "),_c('a',{staticClass:"ant-dropdown-link",staticStyle:{"margin-left":"8px"},on:{"click":function($event){return _vm.handleClickUpdateResult(record)}}},[_c('a-icon',{attrs:{"type":"edit"}})],1)])}}])})],1)])]),_c('FormUpdateResult',{attrs:{"visible":_vm.showFormResult,"candidate":_vm.candidateSelected},on:{"close":_vm.handleCloseFormResult,"on-update":_vm.handleUpdateResult}}),_c('FormUpdatePointStylist',{attrs:{"visible":_vm.showFormPoint,"candidate":_vm.candidateSelected},on:{"close":_vm.handleCloseFormPoint,"on-update":_vm.handleUpdatePoint}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }