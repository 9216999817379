var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-drawer',{attrs:{"title":"Kết quả kiểm tra chuyên môn Stylist","width":720,"visible":_vm.visible,"body-style":{ paddingBottom: '80px' },"wrapClassName":"form-update-point"},on:{"close":_vm.onClose}},[_c('a-spin',{attrs:{"spinning":_vm.isLoading}},[_c('a-row',[_c('a-col',{staticClass:"text-center",attrs:{"span":7}},[_vm._v("\n                Ngày phỏng vấn:\n                "),_c('span',{staticClass:"text-bold"},[_vm._v(_vm._s(_vm.currentDate || ''))])]),_c('a-col',{staticClass:"text-center",attrs:{"span":10}},[_vm._v("\n                Họ tên ứng viên:\n                "),_c('span',{staticClass:"text-bold"},[_vm._v(_vm._s(_vm.candidate.fullName || ''))])]),_c('a-col',{staticClass:"text-center",attrs:{"span":7}},[_vm._v("\n                Số điện thoại:\n                "),_c('span',{staticClass:"text-bold"},[_vm._v(_vm._s(_vm.candidate.phone || ''))])])],1),_c('div',{staticClass:"content-form"},[_c('a-form',{attrs:{"label-align":"left","layout":"vertical"}},_vm._l((_vm.listPoint),function(points,name){return _c('div',{key:name},[_c('a-divider',{staticClass:"title-field ",attrs:{"orientation":"left"}},[_vm._v("\n                        "+_vm._s(name)+"\n                    ")]),_c('a-row',{attrs:{"gutter":40}},_vm._l((points),function(point){return _c('a-col',{key:point.id.skillTestId,attrs:{"span":12}},[_c('a-form-item',_vm._b({attrs:{"label":((point.skillTest.label) + ":")}},'a-form-item',Object.assign({}, _vm.formItemLayout,
                                    _vm.checkError(point)),false),[_c('a-input',{model:{value:(point.value),callback:function ($$v) {_vm.$set(point, "value", $$v)},expression:"point.value"}},[(point.skillTest.unit)?_c('div',{staticStyle:{"width":"50px"},attrs:{"slot":"addonAfter"},slot:"addonAfter"},[_vm._v("\n                                        "+_vm._s(point.skillTest.unit)+"\n                                    ")]):_vm._e()])],1)],1)}),1)],1)}),0)],1)],1),_c('div',{style:({
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'left',
            zIndex: 1,
        })},[_c('a-button',{style:({ marginRight: '8px' }),attrs:{"type":"primary"},on:{"click":_vm.handleSubmit}},[_vm._v("\n            Hoàn tất\n        ")]),_c('a-button',{on:{"click":_vm.onClose}},[_vm._v("\n            Hủy\n        ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }